// $primary: #a51616;
@import url('https://fonts.googleapis.com/css?family=Aubrey|Dosis');

@import url('https://fonts.googleapis.com/css?family=Bangers');

$primary: #C44E9D; /* MAIN COLOR */
$secondary: #049999; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

.flash {
	display:none;
}
body {
	font-size: 1.5em;
	font-family: 'Dosis', sans-serif;
	padding-right: 0 !important;
}

h1,h2,h3,h4,h5,h6 {
	font-family: 'Aubrey', cursive;
}
.top-pad {
	color: $blk;
	font-family: 'Dosis', sans-serif;
	margin-top: 120px;
	font-size: 1em;
	h1,h2,h3,h4,h5,h6 {
		font-family: 'Aubrey', cursive;
		font-size: 2em;
	}
	p, span, a, ol {
		color: $blk;

	}
	li {
		font-size: 1.5em;
	}
}
// / unset bs3 setting /
.modal-open {
 overflow: auto;
}
.logo {
 	@media(max-width:767px){
		max-height: 70px;
	}
}
nav {
	z-index: 1000;
	background: $primary;
}
.navbarFixed {
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 999;
}
.logo {
	@media(max-width:767px){
		max-height: 70px;
	}
}

.navbar .navbar-nav {
    > li > a {
		text-align: center;
		height: 100px;
		display: flex;
		align-items: center;
		color: $blk;



		@media (max-width: 767px) {
		    height: 22px;
		    display: inline-block;
		}

	    &:hover {
	    	background: $secondary;
	    	color: lighten($secondary, 50%);
	    }
			&:focus {
				outline: none;
				background: #f8f8f8;
				color: $blk;
			}

	}
}
.nav-divider {
	margin: 0!important;
}
a {
	text-decoration: none;
	&:hover {
		text-decoration: none;
	}
}
.navbar-right {
	margin-top: 0px;
}

.navbar-toggle {
    margin: 18px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;

	@media (max-width: 388px) {
		margin-top: 14px;
	}
}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn {
	border: none;
	border-radius: 5px;
	background: $primary;
	color: $wht;
	padding: 1em 2em;
	font-size: 1.2em;
	font-weight: 400;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 1px;
	display: inline-block;
	margin: 1em 0em;
	transition: 0.7s ease all;

	&:hover {
		background: $wht;
		color: $primary;
		box-shadow: 0 0 10px $primary;
		text-decoration: none;

		@media (max-width: 1024px) {
			background: $secondary;
		}
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}
.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;

	.close {
		display: none;
	}

	.modal-content {
		color: $primary;

		h2 {
			text-align: center;
		}
	}

	input {
		border: 1px solid #777;
		text-align: center;
	}

	input#username,
	input#password {
		@media (max-width: 1024px) {
			font-size: 16px;
		}
	}

	button {
		background: transparent;
		color: $wht;
		display: block;
		border: none;
		padding: 1em 2em;
		margin: 0 auto;
		font-size: 18px;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border: none;
		}
	}

	input[type="submit"] {
		@include btn;
		display: block;
		width: 100%;
		font-size: 18px;
		margin: 0 auto;
	}
}

.modal-header,
.modal-footer {
	background: $primary;
	color: $wht;
}

.modal-open {
	overflow: auto;
	padding-right: 0px !important;
}

input#username {
	margin-bottom: 20px;
}
// start sections
section {
	padding: 50px 0;
	position: relative;

}
.section-a{
	padding:350px 0;
	background: url('../img/bg1.jpg') no-repeat top center/cover fixed;
	margin-top: 100px;
	@media(max-width:1024px){
		background: url('../img/bg1.jpg') no-repeat top center/cover scroll;
			padding:150px 0;
	}
	@media(max-width:767px){
			padding:70px 0;
			margin-top: 70px;
	}
	h1 {
		@media(max-width:767px){
			font-size: 1.2em;
			color: $wht;
		}
	}

}
.section-b{
	padding: 50px 0;
	background: url('../img/bg3.jpg') no-repeat center/cover;
	@media(max-width:767px){
		padding: 10px 0;
	}
}
.section-c{
	padding: 250px 0;
	background: url('../img/bg2.jpg') no-repeat center/cover fixed;
	@media(max-width:1024px){
		background: url('../img/bg2.jpg') no-repeat center/cover scroll;
		padding: 150px 0;
	}
	@media(max-width:767px){
		padding: 50px 0;
	}
	h1 {
		font-size: 2em;
		@media(max-width:767px){
			font-size: 1.5em;
		}
	}
	p {
		@media(max-width:767px){
		font-size: 1em;
		}
	}
}
.section-d{
	padding: 150px 0;
	background: $primary;
	color: darken($secondary, 25%);
	@media(max-width:767px){
		padding: 50px 0;
	}
	h1 {
		font-size: 1.8em;
		color: $secondary;
		text-shadow: 0px 0px 1px darken($secondary, 25%);
		@media(max-width: 767px){
				text-align: center;
				font-size: 1.2em;
			}
	}
	p {
		text-shadow: 0px 0px 1px darken($wht, 25%);
		color: $wht;
			font-size: 1.3em;
		@media (max-width: 767px){
			font-size:1em;
			text-align: center;
		}
	}

}
// ends sections
h1 {
	font-size:  2.5em;
	line-height: 1.5em;
	color: $primary;
	text-shadow: 3px 3px 8px darken($secondary, 25%);
	text-transform: uppercase;
}
p {
	font-size: 1.7em;
	color: $wht;
}
/** END LOGIN FORM **/
.cta {
	background: darken($primary, 15%);
	color:$wht;
	padding: 10px 25px;
	transition: all 0.7s ease 0s;
	font-size: 1.2em;
	margin: 5px;

	&:hover{
		background: darken($secondary, 15%);
		color:$wht;
	}
}
footer {
	padding: 20px 0px 20px;
	background: $secondary;
	color: $footerLinks;
	font-size: .5em;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}
